
.generalContainerLanding{
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );

    .sectionOne{
        min-height: 70vh;
        background-image: url('../../landing/fondoMobil.png');
        background-size: cover;

        background-position: center;
        background-size: 110%;
        background-position-x: -5vw;
        background-position-y: 0vh;
        background-repeat: no-repeat;


        .header{
            display: flex;
            .footersectionOne{
                min-width: 80vw;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                label{
                    min-width: 75vw;
                    max-width: 75vw;
                }
                .texone{
                    margin-top: 2vh;
                    font-size: 1.7rem;
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 12vh;
                    font-family: Gabi;
                    min-width: 100vw;
                   img{
                   }
                }
                .textwo{
                    color: #fff;
                    font-size: 1.3rem;
                    min-height: 8vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Avenir;
                    font-style: oblique;
                }
            }
            .footersectionTwo{
                position: absolute;
                min-width: 20vw;
                min-height: 10vh;
                max-height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    min-width: 10vw;
                }
            }
        }
      
     
      
    }


    .sectionCard{
        max-width: 100vw;
        z-index: 2;
        min-height: 10vh;
        padding-bottom: 5vh; 
        padding-top: 5vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        font-size: 1.4rem;
        text-align: center;
        color: #966B48;
        font-family: Gabi;       
        font-style: oblique;
       background-image: linear-gradient(to top, transparent 10%  , #FFF 50%  ,transparent 100% );
        text{
            font-family: Avenir;
        }
    }


    .sectionForm{
        z-index: 5;
        max-width: 100vw;
        display: flex;
        .filtro{
            position: absolute;
            margin-top: 17vh;
            min-height: 83vh;
            min-width: 85vw;
            z-index: 1;
            background-color: rgba(255, 255, 255 ,0.1);
        }
        form{ 
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 5;
            img{
                
            }
            div{
                z-index: 5;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 10vh;
                font-family: Avenir;

                .subtitleUno{
                    font-weight: 400;
                    margin-top: 2vh;
                    font-size: 1.2rem;
                    text-align: center;
                    letter-spacing: 0.05rem;
                    max-width: 100vw;
                    min-width: 100vw;
                    color: #887967;
                    font-family: Avenir;
                    font-style: oblique;
                    margin-bottom: 4vh;
                }


                input,select{
                    color: #FFF;
                    min-width: 70vw;              
                    min-height: 4vh;
                    background-color: transparent;                                
                    color: #FFF;
                    font-size: 1.2rem;
                    text-align: center;
                    font-family: Avenir;
                    border: none;
                    border-bottom: 1px solid #FFF;
                    font-weight: 600;
                    text-align: left;
                }
                select{
                    max-width: 20vw;
                    min-width: 20vw;
                }
                label{
                    z-index: 5;
                    font-size: 1rem;
                    color:#FFF;
                    letter-spacing: 0.2rem;
     
                }
                input::placeholder,  select::placeholder{
                    color: #FFF;
                    font-weight: 400;
                    font-size: 0.9rem;
                    text-align: left;
                }
            }

            .flechasImagen{
                margin-bottom:2vh;
            }
            .entradasRadios{
                min-height: 10vh;
                display: flex;
                flex-direction: column;
                label{
                    margin-top: 2vh;
                    min-width: 70vw;
                    max-width: 70vw;
                    margin-bottom: 2vh;
                    color: #FFF;
                    letter-spacing: 0rem;
                    border-bottom: 1px solid #FFF;
                    //border: none;
                }
                div{
                    display: flex;
                    flex-direction: row;
                    min-width: 75vw;
                    text-align: left;
                    align-items: center;
                    justify-content: flex-start;
                    max-height: 5vh;
                    min-height: 5vh;
                    .radioButton{
                        border: 1px solid #FFF;
                        background-color: #FFF;
                        min-width: 10px;
                        min-height: 10px;
                        border-radius: 10px;
                        margin-right: 0.5rem;
                    
                    }
                    .radioButtonSelected{
                        border: 1px solid #707070;
                        background-color: #707070;
                        min-width: 10px;
                        min-height: 10px;
                        border-radius: 10px;
                        margin-right: 0.5rem;
                    }
                    text{
                        color: #FFF;
                    }
                }
          
            }

            .agendacita{
                display: flex;
                min-height: 5vh;
                max-width: 80vw;
                min-width: 80vw;
                text-align: center;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                font-size: 1.2rem;

                color: #966B48;
                label{
                    min-height: 5vh;
                    font-family: Avenir;
                    font-size: 1.3rem;
                    color: #966B48;
                    display: flex;
                    flex-direction: column;
                    letter-spacing: 0rem;
                    text{
                        font-weight: 600;
                        color: #966B48;
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: column;
                        letter-spacing: 0rem;
                    }
                }
                hr{
                    min-width: 15vw;
                }
            }
        }

    }
   
    .giftcard{
        min-width: 80vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 15vh;
        button{
            min-width: 65vw;
            min-height: 7vh;
            border-radius: 8vw;
            border: none;
            color: #966B48;
            border-color: #FFF;
            background-color: #FFF;
            font-size: 1.2rem;
            font-family: Avenir;
            font-weight: 600;
        }
    }

    .footer{
        min-width: 90vw;
        display: flex;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            margin-bottom: 2vh;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 400;
                line-height: 1.5rem;
              
                a{
                    color: #FFF;
                }
                
            }
            img{
                display: none;
                margin-top: 5vh;
                margin-bottom: 5vh;
                max-width: 50vw;
            }
        }
    }
    
}



/*RESOLUCIONES MOBILES*/


@media (min-width:360px) and (min-height: 640px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );        
        .sectionOne{
            min-height: 70vh;
            background-image: url('../../landing/fondoMobil.png');
            background-size: cover;
    
            background-position: center;
            background-size: 110%;
            background-position-x: -5vw;
            background-position-y: 0vh;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        min-width: 100vw;
                       img{
                       }
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 5vh; 
            padding-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.4rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
           background-image: linear-gradient(to top, transparent 10%  , #FFF 50%  ,transparent 100% );
            text{
                font-family: Avenir;
            }
        }
    
    
        .sectionForm{
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{
                position: absolute;
                margin-top: 16vh;
                min-height: 81vh;
                min-width: 85vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #887967;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 4vh;
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 70vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: column;
                    label{
                        margin-top: 2vh;
                        min-width: 70vw;
                        max-width: 70vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 75vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                        }
                        text{
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 80vw;
                    min-width: 80vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: column;
                        letter-spacing: 0rem;
                        min-height: 0vh;
                        text{
                            font-weight: 600;
                            color: #966B48;
                            min-height: 0vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            min-width: 80vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            button{
                z-index: 80;
                min-width: 65vw;
                min-height: 7vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{

                label{                
                    a{
                    }
                    
                }
                img{
                }
            }
        }
        
    }
    
    
    

}

@media (min-width:360px) and (min-height: 780px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 58vh;
            background-image: url('../../landing/fondoMobil.png');
            background-size: cover;    
            background-position: center;
            background-size: 110%;
            background-position-x: -5vw;
            background-position-y: 0vh;
            background-repeat: no-repeat;        
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        min-width: 100vw;
                       img{
                       }
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }                             
        }        
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 5vh; 
            padding-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.4rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 10%  , #FFF 50%  ,transparent 100% );
            text{
                font-family: Avenir;
            }
        }        
        .sectionForm{
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{
                position: absolute;
                margin-top: 15vh;
                min-height: 82vh;
                min-width: 85vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                img{                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #887967;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 4vh;
                    }        
                    input,select{
                        color: #FFF;
                        min-width: 70vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }    
                .flechasImagen{
                    margin-bottom:2vh;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: column;
                    label{
                        margin-top: 2vh;
                        min-width: 70vw;
                        max-width: 70vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 75vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                        }
                        text{
                            color: #FFF;
                        }
                    }              
                }    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 80vw;
                    min-width: 80vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;    
                    color: #966B48;
                    label{
                        margin-top: 1vh;
                        min-height: 0vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: column;
                        letter-spacing: 0rem;
                        text{
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
        }
        .giftcard{
            min-width: 80vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            button{
                min-width: 65vw;
                min-height: 7vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
        }
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-bottom: 2vh;
                label{
                    min-width: 95vw;
                    max-width: 95vw;
                    font-size: .9rem;
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
    }
}

@media (min-width:360px) and (min-height: 800px)  {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 56vh;
            .header{
                .footersectionOne{
                    label{
                    }
                    .texone{
                       img{
                       }
                    }
                    .textwo{
                    }
                }
                .footersectionTwo{
                    img{
                    }
                }
            }                             
        }        
        .sectionCard{
            text{
            }
        }        
        .sectionForm{
            .filtro{
                margin-top: 15vh;
            }
            form{ 
                img{                    
                }
                div{
                    .subtitleUno{
                    }        
                    input,select{
                    }
                    select{
                    }
                    label{
                    }
                    input::placeholder,  select::placeholder{
                    }
                }    
                .flechasImagen{
                }
                .entradasRadios{
                    label{
                
                    }
                    div{
                        .radioButton{
                          
                        }
                        text{
                        }
                    }              
                }    
                .agendacita{
                   
                    label{
                        min-height: 0vh;
                        text{
                        
                        }
                    }
                    hr{
                    }
                }
            }
        }
        .giftcard{
          
            button{
           
            }
        }
        .footer{
            div{
                label{
                    a{
                    }
                }
                img{
                }
            }
        }
    }

}

@media (min-width:390px) and (min-height: 844px)  {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 58vh;
            .header{
                .footersectionOne{
                    label{
                    }
                    .texone{
                       img{
                       }
                    }
                    .textwo{
                    }
                }
                .footersectionTwo{
                    img{
                    }
                }
            }                             
        }        
        .sectionCard{
            text{
            }
        }        
        .sectionForm{
            .filtro{
                margin-top: 14vh;
            }
            form{ 
                img{                    
                }
                div{
                    .subtitleUno{
                    }        
                    input,select{
                    }
                    select{
                    }
                    label{
                    }
                    input::placeholder,  select::placeholder{
                    }
                }    
                .flechasImagen{
                }
                .entradasRadios{
                    label{
                
                    }
                    div{
                        .radioButton{
                          
                        }
                        text{
                        }
                    }              
                }    
                .agendacita{
                   
                    label{
                     
                        text{
                        
                        }
                    }
                    hr{
                    }
                }
            }
        }
        .giftcard{
          
            button{
           
            }
        }
        .footer{
            div{
                label{
                    a{
                    }
                }
                img{
                }
            }
        }
    }
}

@media (min-width:393px) and (min-height: 873px)  {

}

@media (min-width:414px) and (min-height: 715px)  {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 57vh;
            .header{
                .footersectionOne{
                 
                    label{
                    }
                    .texone{
                       img{
                       }
                    }
                    .textwo{
                    }
                }
                .footersectionTwo{
                    img{
                    }
                }
            }                             
        }        
        .sectionCard{
            margin-top: -2vh;
            text{
            }
        }        
        .sectionForm{
            .filtro{
                margin-top: 13vh;
            }
            form{ 
                img{                    
                }
                div{
                    .subtitleUno{
                    }        
                    input,select{
                    }
                    select{
                    }
                    label{
                    }
                    input::placeholder,  select::placeholder{
                    }
                }    
                .flechasImagen{
                }
                .entradasRadios{
                    label{
                
                    }
                    div{
                        .radioButton{
                          
                        }
                        text{
                        }
                    }              
                }    
                .agendacita{
                   
                    label{
                     
                        text{
                        
                        }
                    }
                    hr{
                    }
                }
            }
        }
        .giftcard{
          
            button{
           
            }
        }
        .footer{
            div{
                label{
                    a{
                    }
                }
                img{
                }
            }
        }
    }
}


@media (min-width: 414px) and (max-width: 767px) { 
    
}


@media (min-width:412px) and (min-height: 915px)  {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 57vh;
            .header{
                .footersectionOne{
                 
                    label{
                    }
                    .texone{
                       img{
                       }
                    }
                    .textwo{
                    }
                }
                .footersectionTwo{
                    img{
                    }
                }
            }                             
        }        
        .sectionCard{
            margin-top: -2vh;
            text{
            }
        }        
        .sectionForm{
            .filtro{
                margin-top: 13vh;
            }
            form{ 
                img{                    
                }
                div{
                    .subtitleUno{
                    }        
                    input,select{
                    }
                    select{
                    }
                    label{
                    }
                    input::placeholder,  select::placeholder{
                    }
                }    
                .flechasImagen{
                }
                .entradasRadios{
                    label{
                
                    }
                    div{
                        .radioButton{
                          
                        }
                        text{
                        }
                    }              
                }    
                .agendacita{
                   
                    label{
                     
                        text{
                        
                        }
                    }
                    hr{
                    }
                }
            }
        }
        .giftcard{
          
            button{
           
            }
        }
        .footer{
            div{
                label{
                    a{
                    }
                }
                img{
                }
            }
        }
    }
}

@media (min-width:414px) and (min-height: 896px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );            
        .sectionOne{
            min-height: 58vh;
            .header{
                .footersectionOne{
                 
                    label{
                    }
                    .texone{
                       img{
                       }
                    }
                    .textwo{
                    }
                }
                .footersectionTwo{
                    img{
                    }
                }
            }                             
        }        
        .sectionCard{
            margin-top: -2vh;
            text{
            }
        }        
        .sectionForm{
            .filtro{
                margin-top: 13vh;
            }
            form{ 
                img{                    
                }
                div{
                    .subtitleUno{
                    }        
                    input,select{
                    }
                    select{
                    }
                    label{
                    }
                    input::placeholder,  select::placeholder{
                    }
                }    
                .flechasImagen{
                }
                .entradasRadios{
                    label{
                
                    }
                    div{
                        .radioButton{
                          
                        }
                        text{
                        }
                    }              
                }    
                .agendacita{
                   
                    label{
                     
                        text{
                        
                        }
                    }
                    hr{
                    }
                }
            }
        }
        .giftcard{
          
            button{
           
            }
        }
        .footer{
            div{
                label{
                    a{
                    }
                }
                img{
                }
            }
        }
    }
}



@media (min-width: 420px) and (min-height:925px){ 
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #968B6F 30%, #C8B29B 50%, #FFF 60%,#FFF 100% );        
        .sectionOne{
            min-height: 60vh;
            background-image: url('../../landing/fondoMobil.png');
            background-size: cover;
    
            background-position: center;
            background-size: 110%;
            background-position-x: -5vw;
            background-position-y: 0vh;
            background-repeat: no-repeat;
            background-color: transparent;
            .header{
                display: flex;
                background-color: transparent;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    background-color: transparent;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 2vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 12vh;
                        font-family: Gabi;
                        min-width: 100vw;
                       img{
                       }
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 5vh; 
            padding-top: 0vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.4rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
           background-image: linear-gradient(to top, transparent 10%  , #FFF 50%  ,transparent 100% );
            text{
                font-family: Avenir;
            }
        }
    
    
        .sectionForm{
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{
                position: absolute;
                margin-top: 14vh;
                min-height: 79vh;
                min-width: 85vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #887967;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 4vh;
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 70vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: column;
                    label{
                        margin-top: 2vh;
                        min-width: 70vw;
                        max-width: 70vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 75vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                        }
                        text{
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 80vw;
                    min-width: 80vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: column;
                        letter-spacing: 0rem;
                        min-height: 0vh;
                        text{
                            font-weight: 600;
                            color: #966B48;
                            min-height: 0vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            min-width: 80vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            button{
                z-index: 80;
                min-width: 65vw;
                min-height: 7vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{

                label{                
                    a{
                    }
                    
                }
                img{
                }
            }
        }
        
    }
    
}


/*FIN RESOLUCIONES MOBILES*/





/* INICIO DE RESOLUCION IPAD */

@media (min-width:820px) and (min-height: 1073px)  and (orientation: portrait) {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #A29479 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            position: absolute;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoDesktop.png');
            background-position: center;
            background-size: 130%;            
            background-position-x: -10vw;

            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 0vh;
                        font-size: 1.2rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 10vh;
                        font-family: Gabi;
                        min-width: 20vw;
                        margin-right: 20vw;
                       img{
                           min-width: 35vw;
                       }
                    }
                    .textwo{
                         
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            
            position: absolute;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 0vh; 
            padding-top: 8vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 60vw;
            font-size: 2rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.2rem;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;

            display: flex;
            flex-direction: column;
            .filtro{
                position: absolute;
                margin-top: 12vh;
                min-height: 58vh;
                min-width: 40vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 20vh;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 5vh;
                        br{
                        }
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 30vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                    display: none;
                }
                .entradasRadios{
                    z-index: 50;
                    min-height: 10vh;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        margin-top: 2vh;
                        margin-left: 5vw;
                        min-width: 30vw;
                        max-width: 30vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                        border: none;
                    }
                    div{
                        z-index: 100;
                        display: flex;
                        flex-direction: row;
                        min-width: 17vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            z-index: 100;
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                            font-size: 2rem;
                        
                        }
                        .radioButtonSelected{
                            border: 1px solid #707070;
                            background-color: #707070;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                        }
                        text{
                            font-size: 0.8rem;
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 40vw;
                    min-width: 40vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    label{
                        min-height: 0vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: row;
                        letter-spacing: 0rem;
                        text{
                              //line-height: 2rem;
                            margin-left: 0.5rem;
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            margin-left: 60vw;
            margin-top: 78vh;
            z-index: 10;
            button{
                min-width: 30vw;
                min-height: 5vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
            button:hover{
                background-color: #966B48;
                color: #FFF;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 93vh;
            margin-left: 60vw;
            z-index: 30;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 40vw;
                    max-width: 40vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1rem;
                  
                    a{
                        min-width: 30vw;
                    }
                    
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    

}
@media (min-width:1180px) and (min-height: 713px)  and (orientation: landscape) {

    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #A29479 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            position: absolute;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoDesktop.png');
            background-position: center;
            background-size: 100%;            
            background-position-x: 0vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 0vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 10vh;
                        font-family: Gabi;
                        min-width: 20vw;
                        margin-right: 38vw;
                       img{
                           min-width: 18vw;
                       }
                    }
                    .textwo{
                         
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            
            position: absolute;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 0vh; 
            padding-top: 8vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 60vw;
            font-size: 2rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;

            display: flex;
            flex-direction: column;
            .filtro{
                position: absolute;
                margin-top: 14vh;
                min-height: 55vh;
                min-width: 40vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 20vh;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 10vh;

                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 30vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                    display: none;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        margin-top: 2vh;
                        margin-left: 5vw;
                        min-width: 30vw;
                        max-width: 30vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                        border: none;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 17vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                            font-size: 2rem;
                        
                        }
                        .radioButtonSelected{
                            border: 1px solid #707070;
                            background-color: #707070;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                        }
                        text{
                            font-size: 0.8rem;
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 40vw;
                    min-width: 40vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: row;
                        letter-spacing: 0rem;
                        text{
                            margin-left: 0.5rem;
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height:7vh;
            margin-left: 60vw;
            margin-top: 80vh;
            z-index: 10;
            button{
                min-width: 30vw;
                min-height: 5vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
            button:hover{
                background-color: #966B48;
                color: #FFF;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 93vh;
            margin-left: 60vw;
            z-index: 30;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 30vw;
                    max-width: 30vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1rem;
                  
                    a{
                        min-width: 30vw;
                    }
                    
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    

}




/* FIN DE RESOLUCION DE IPAD */









/* RESOLUCIONES  DESKTOP */
@media (min-width:1280px) and (min-height: 720px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #A29479 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{
            position: absolute;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoDesktop.png');
            background-position: center;
            background-size: 100%;            
            background-position-x: 0vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        margin-top: 0vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 10vh;
                        font-family: Gabi;
                        min-width: 20vw;
                        margin-right: 38vw;
                       img{
                           min-width: 18vw;
                       }
                    }
                    .textwo{
                        
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            
            position: absolute;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 0vh; 
            padding-top: 8vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 60vw;
            font-size: 2rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;

            display: flex;
            flex-direction: column;
            .filtro{
                position: absolute;
                margin-top: 10vh;
                min-height: 58vh;
                min-width: 40vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 20vh;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 10vh;

                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 30vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                    display: none;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        margin-top: 2vh;
                        margin-left: 5vw;
                        min-width: 30vw;
                        max-width: 30vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                        border: none;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 17vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                            font-size: 2rem;
                        
                        }
                        .radioButtonSelected{
                            border: 1px solid #707070;
                            background-color: #707070;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                        }
                        text{
                            font-size: 0.8rem;
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 40vw;
                    min-width: 40vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: row;
                        letter-spacing: 0rem;
                        text{
                            margin-left: 0.5rem;
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            margin-left: 60vw;
            margin-top: 75vh;
            z-index: 10;
            button{
                min-width: 30vw;
                min-height: 5vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
            button:hover{
                background-color: #966B48;
                color: #FFF;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 93vh;
            margin-left: 59vw;
            z-index: 30;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    
}

@media (min-width:1440px) and (min-height: 900px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #A29479 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            position: absolute;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoDesktop.png');
            background-position: center;
            background-size: 100%;            
            background-position-x: 0vw;
            background-repeat: no-repeat;
    
    
            .header{

                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{

                        margin-top: 0vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 10vh;
                        font-family: Gabi;
                        min-width: 20vw;
                        margin-right: 38vw;
                       img{
                           min-width: 18vw;
                       }
                    }
                    .textwo{
                        
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            
            position: absolute;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 0vh; 
            padding-top: 8vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 60vw;
            font-size: 2rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;

            display: flex;
            flex-direction: column;
            .filtro{
                position: absolute;
                margin-top: 10vh;
                min-height: 58vh;
                min-width: 40vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 20vh;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 10vh;

                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 30vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                    display: none;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        margin-top: 2vh;
                        margin-left: 5vw;
                        min-width: 30vw;
                        max-width: 30vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                        border: none;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 17vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                            font-size: 2rem;
                        
                        }
                        .radioButtonSelected{
                            border: 1px solid #707070;
                            background-color: #707070;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                        }
                        text{
                            font-size: 0.8rem;
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 40vw;
                    min-width: 40vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: row;
                        letter-spacing: 0rem;
                        max-height: 5vh;
                        margin-top: 0;
                        text{
                            margin-left: 0.5rem;
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                   
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            margin-left: 60vw;
            margin-top: 75vh;
            z-index: 10;
            button{
                min-width: 30vw;
                min-height: 5vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
            button:hover{
                background-color: #966B48;
                color: #FFF;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 93vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    
}

@media (min-width:1366px) and (min-height: 768px)  {

}

@media (min-width:1536px) and (min-height: 864px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #A29479 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .sectionOne{

            position: absolute;
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoDesktop.png');
            background-position: center;
            background-size: 100%;            
            background-position-x: 0vw;
            background-repeat: no-repeat;
    
    
            .header{

                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{

                        margin-top: 0vh;
                        font-size: 1.7rem;
                        color: #FFF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 10vh;
                        font-family: Gabi;
                        min-width: 20vw;
                        margin-right: 38vw;
                       img{
                           min-width: 18vw;
                       }
                    }
                    .textwo{
                        
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            
            position: absolute;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 0vh; 
            padding-top: 8vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 60vw;
            font-size: 2rem;
            text-align: center;
            color: #966B48;
            font-family: Gabi;       
            font-style: oblique;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;

            display: flex;
            flex-direction: column;
            .filtro{
                position: absolute;
                margin-top: 10vh;
                min-height: 58vh;
                min-width: 40vw;
                z-index: 1;
                background-color: rgba(255, 255, 255 ,0.1);
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 20vh;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 6vh;
                    font-family: Avenir;
    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 10vh;

                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        color: #FFF;
                        min-width: 30vw;              
                        min-height: 4vh;
                        background-color: transparent;                                
                        color: #FFF;
                        font-size: 1.2rem;
                        text-align: center;
                        font-family: Avenir;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        font-weight: 600;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    margin-bottom:2vh;
                    display: none;
                }
                .entradasRadios{
                    min-height: 10vh;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    label{
                        margin-top: 2vh;
                        margin-left: 5vw;
                        min-width: 30vw;
                        max-width: 30vw;
                        margin-bottom: 2vh;
                        color: #FFF;
                        letter-spacing: 0rem;
                        border-bottom: 1px solid #FFF;
                        border: none;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        min-width: 17vw;
                        text-align: left;
                        align-items: center;
                        justify-content: flex-start;
                        max-height: 5vh;
                        min-height: 5vh;
                        .radioButton{
                            border: 1px solid #FFF;
                            background-color: #FFF;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                            font-size: 2rem;
                        
                        }
                        .radioButtonSelected{
                            border: 1px solid #707070;
                            background-color: #707070;
                            min-width: 10px;
                            min-height: 10px;
                            border-radius: 10px;
                            margin-right: 0.5rem;
                            margin-left: 5vw;
                        }
                        text{
                            font-size: 0.8rem;
                            color: #FFF;
                        }
                    }
              
                }
    
                .agendacita{
                    display: flex;
                    min-height: 5vh;
                    max-width: 40vw;
                    min-width: 40vw;
                    text-align: center;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 1.2rem;
                    color: #966B48;
                    
                    label{
                        min-height: 5vh;
                        font-family: Avenir;
                        font-size: 1.3rem;
                        color: #966B48;
                        display: flex;
                        flex-direction: row;
                        letter-spacing: 0rem;
                        max-height: 5vh;
                        margin-top: 0;
                        text{
                            margin-left: 0.5rem;
                            font-weight: 600;
                            color: #966B48;
                            min-height: 5vh;
                            font-family: Avenir;
                            font-size: 1.3rem;
                            color: #966B48;
                            display: flex;
                            flex-direction: column;
                            letter-spacing: 0rem;
                   
                        }
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            margin-left: 60vw;
            margin-top: 75vh;
            z-index: 10;
            button{
                min-width: 30vw;
                min-height: 5vh;
                border-radius: 8vw;
                border: none;
                color: #966B48;
                border-color: #FFF;
                background-color: #FFF;
                font-size: 1.2rem;
                font-family: Avenir;
                font-weight: 600;
            }
            button:hover{
                background-color: #966B48;
                color: #FFF;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 93vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
        
    }
    

}

@media (min-width:1600px) and (min-height: 900px)  {

}

@media (min-width:1920px) and (min-height: 1080px)  {

}






/* FIN RESOLUCIONES DESKTOP*/























